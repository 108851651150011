import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import styles from './faq.module.scss'

const FaqPage = ({ pageContext: { locale, hrefLangs }, data }) => (
  <Layout locale={locale}>
    <SEO
      title="FAQ"
      description="Frequently Asked Questions"
      hrefLangs={hrefLangs}
    />
    <main role="main" className={styles.main}>
      <section className={styles.hero}>
        <h1 className={styles.hero_heading}>
          <FormattedMessage id="faq" />
        </h1>
        <figure className={styles.hero_img}>
        <Img fluid={data.faq.childImageSharp.fluid} />
          </figure>
      </section>
      <div className="wrapper">
        <section className={styles.faq}>
          <h2>
            <FormattedMessage id="faq" />
          </h2>
          <div className={styles.faq_content}>
            {data.allContentfulFaq.edges.map(({ node }) => (
              <React.Fragment key={node.title}>
                <label htmlFor={`item-` + node.id}>{node.title}</label>
                <input type="checkbox" id={`item-` + node.id} />
                <ul>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: node.content.childMarkdownRemark.html,
                    }}
                  />
                </ul>
              </React.Fragment>
            ))}
          </div>
        </section>
      </div>
    </main>
  </Layout>
)

export default FaqPage

export const query = graphql`
  query($localeLanguage: String!) {
    allContentfulFaq(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: $localeLanguage } }
    ) {
      edges {
        node {
          id
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    faq: file(relativePath: { eq: "head.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
